<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/lubricaOilManage' }">润滑油档案</el-breadcrumb-item>
        <el-breadcrumb-item>档案详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="form-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="型号：" prop="model">
              {{ form.model }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编码：" prop="code">
              {{ form.code }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="品牌：" prop="brand">
              {{ form.brand }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：" prop="specification">
              {{ form.specification }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="分类：" prop="classification">
              {{ form.classification }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="等级：" prop="level">
              {{ form.level }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生产厂家：" prop="manufacturer">
              {{ form.factory }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他描述：" prop="otherDesc">
              {{ form.description && form.description!='null' ? form.description : '无' }}
            </el-form-item>
          </el-col>
        </el-row>

        <div class="form-title">管理信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="departmentId">
              {{ form.department }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人：" prop="staffId">
              {{ form.staffName }}
            </el-form-item>
          </el-col>
        </el-row>
        
        <div class="form-title">设备图片</div>
        <div style="margin-bottom: 20px;" v-if="form.fileInfos.length!=0">
          <el-image 
            class="images"
            v-for="(item,index) in form.fileInfos"
            :key="index"
            :src="item.fileUrl"
            :preview-src-list="[item.fileUrl]">
          </el-image>
        </div>
        <div style="margin-bottom: 20px;" v-else>（空）</div>
        <div class="bottom-btn">
          <el-button type="primary" size="small" @click="$router.push('/lubricaOilManage')">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        fileInfos:[]
      },
      rules: {},
      disabled: false,
      row: {}
    };
  },
  methods: {
    // 获取检修计划详情数据
    loadArchivesData() {
      this.loading = true
      this.$ajax.post("lubeManagerDetail", {
        id: this.row.id
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/lubricaOilManage");
      return;
    }
    this.row = this.$route.params.row;
    this.loadArchivesData()
  },
};
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
.images {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  margin: 0 8px 8px 0;
  display: inline-block;
}
</style>